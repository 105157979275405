<template>
  <div class="add">
    <div class="title">
      <h2>Add</h2>
    </div>
    <el-form :model="addData" :inline="true" label-width="100px" ref="addData" :rules="rules">
      <div class="infotitle">
        <h3>
          <label>-- Offer Info --</label>
        </h3>
      </div>
      <el-form-item label="OfferName" prop="offerName">
        <el-input v-model="addData.offerName" placeholder="offerName"></el-input>
      </el-form-item>
      <el-form-item label="OfferDescription" label-width="120px">
        <el-input type="textarea" :rows="2" v-model="addData.description"></el-input>
      </el-form-item>
      <el-form-item label="Category">
        <el-input
          v-model="addData.category"
          placeholder="category,category,eg.games,install...."
        ></el-input>
      </el-form-item>
      <el-form-item label="PrevewLink" prop="previewLink">
        <el-input v-model="addData.previewLink" placeholder="previewLink"></el-input>
      </el-form-item>
      <el-form-item label="TrafficAllowed">
        <el-input v-model="addData.trafficAllowed" placeholder="trafficAllowed,eg.A,B,C"></el-input>
      </el-form-item>
      <el-form-item label="TrafficForbidden" label-width="120px">
        <el-input
          v-model="addData.trafficForbidden"
          placeholder="trafficForbidden,eg.A,B,C"
        ></el-input>
      </el-form-item>
      <el-form-item label="ConversionFlow" label-width="120px">
        <el-input
          v-model="addData.conversionFlow"
          placeholder="trafficForbidden,eg.A,B,C"
        ></el-input>
      </el-form-item>
      <el-form-item label="Incentive">
        <el-select v-model="addData.incentive">
          <el-option
            v-for="item in incentiveOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="OsMinVersion">
        <el-input v-model="addData.osMinVersion" placeholder="osMinVersion,eg. 3.2.0"></el-input>
      </el-form-item>

      <div class="infotitle">
        <h3>
          <label>-- Payout Info --</label>
        </h3>
      </div>
      <el-form-item label="Currency" prop="currency">
        <el-select v-model="addData.currency" placeholder="currency,eg. USD">
          <el-option
            v-for="item in currencyOption"
            :key="item.value"
            :value="item.value"
            :label="item.label"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="PayoutType">
        <el-select v-model="addData.payoutType" @change="SelectPayout">
          <el-option
            v-for="item in payoutOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Payout" prop="payout">
        <el-input v-model="addData.payout" placeholder="payout,eg. 3.2"></el-input>
      </el-form-item>
      <el-form-item label="Revenue" prop="revenue">
        <el-input v-model="addData.revenue" placeholder="revenue"></el-input>
      </el-form-item>
      <el-form-item label="CapDaily">
        <el-input v-model="addData.capDaily" placeholder="capDaily,eg. 100"></el-input>
      </el-form-item>
      <el-form-item label="ClickCapDaily" label-width="130px" prop="clickCapDaily">
        <el-input v-model="addData.clickCapDaily" placeholder="clickCapDaily,eg. 100"></el-input>
      </el-form-item>

      <div class="infotitle">
        <h3>
          <label>-- Setting Info --</label>
        </h3>
      </div>
      <el-form-item label="Platforms" prop="platforms">
        <el-select v-model="addData.platforms" @change="platformsChange">
          <el-option
            v-for="item in platformsOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Countries" prop="countries">
        <el-input v-model="addData.countries" placeholder="countries,eg.HK,US,CN.."></el-input>
      </el-form-item>
      <el-form-item label="Regions" prop="regions">
        <el-input v-model="addData.regions" placeholder="regions"></el-input>
      </el-form-item>
      <el-form-item label="Cities" label-width="100px">
        <el-input v-model="addData.citys" placeholder="citys,eg.Beijing,London,Tokyo.."></el-input>
      </el-form-item>
      <el-form-item label="DownloadType">
        <el-select v-model="addData.downloadType">
          <el-option
            v-for="item in downloadTypeOption"
            :key="item.vlaue"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Carriers" prop="carriers">
        <el-input v-model="addData.carriers" placeholder="carriers,eg.Banana,Apple..." />
      </el-form-item>
      <el-form-item label-width="115px" label="Control Type" prop="type">
        <el-select v-model="addData.type">
          <el-option
            v-for="item in typeOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label-width="85px" label="Direct" prop="direct">
        <el-select v-model="addData.direct" col placeholder="please add direct...">
          <el-option label="direct" value="direct"></el-option>
          <el-option label="on_direct" value="non_direct"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label-width="85px" label="AfPrt" prop="direct">
        <el-input v-model="addData.afPrt" col placeholder="please add af prt..." />
      </el-form-item>

      <div class="infotitle">
        <h3>
          <label>-- Source Info --</label>
        </h3>
      </div>
      <el-form-item label="SourceId" prop="sourceId">
        <el-select v-model="addData.sourceId" :loading="loading" filterable>
          <el-option
            v-for="item in sourceList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label-width="140px" label="SourceOfferId" prop="sourceOfferId">
        <el-input v-model="addData.sourceOfferId" placeholder="sourceOfferId"></el-input>
      </el-form-item>
      <el-form-item label-width="140px" label="SourceTrackingLink" prop="sourceTrackingLink">
        <el-input
          v-model="addData.sourceTrackingLink"
          placeholder="sourceTrackingLink,should configure click_id,subid,gaid,idfa"
          style="width:280px;"
        ></el-input>
      </el-form-item>

      <div v-show="isCPI">
        <div class="infotitle">
          <h3>
            <label>
              -- Prod Info --
              <span style="color:red;">(must import first)</span>
            </label>
          </h3>
        </div>
        <el-form-item label="Pkg Name" prop="prod">
          <el-input
            v-model="addData.prod"
            placeholder="pkgName,eg. com.test.sl.ss / 300225522"
          ></el-input>
        </el-form-item>
      </div>

      <div class="infotitle">
        <h3>
          <label>-- Show Info --</label>
        </h3>
      </div>
      <el-form-item label="Settle Event">
        <el-input v-model="addData.settleEvent" placeholder="Settle Event"></el-input>
      </el-form-item>

      <el-row style="margin:20px;">
        <el-form-item>
          <el-button type="primary" @click="importOfferclick('addData')">Import Offer</el-button>
        </el-form-item>
      </el-row>
    </el-form>
  </div>
</template>
<script>
  import { getSourceList } from 'api/source/index';
  import { getOfferDetail } from 'api/offer/detail';
  import { insertOffer } from 'api/product/add';
  import { myMixin } from '@/mixins/mixins.js';

  const defaulutData = {
    prodId: '',
    prod: '',
    offerName: '',
    description: '',
    category: '',
    previewLink: '',
    productType: '',
    conversionFlow: '',
    incentive: '0',
    trafficAllowed: '',
    trafficForbidden: '',
    osMinVersion: '',
    capDaily: '',
    clickCapDaily: '',
    revenue: '',
    payout: '',
    payoutType: 'CPI',
    currency: 'USD',
    countries: '',
    platforms: '',
    citys: '',
    regions: '',
    carriers: '',
    type: 'private',
    downloadType: '',
    sourceTrackingLink: '',
    sourceId: '',
    sourceOfferId: '',
    settleEvent: '',
    direct: 'direct',
    afPrt: '',
  };
  export default {
    mixins: [myMixin],
    data() {
      return {
        loading: false,
        uploadIconloading: false,
        uploadloading: false,
        uploadIconData: {},
        uploadData: {},
        offerId: null,
        doUpload: null,
        doUploadIcon: null,
        isCPI: true,
        file: null,
        addData: Object.assign({}, defaulutData),
        currencyOption: [
          {
            label: 'USD',
            value: 'USD',
          },
        ],
        asyncOption: [
          {
            label: 'YES',
            value: '1',
          },
          {
            label: 'NO',
            value: '0',
          },
        ],
        asyncRedirectOption: [
          {
            label: 'YES',
            value: '1',
          },
          {
            label: 'NO',
            value: '0',
          },
        ],
        incentiveOption: [
          {
            label: 'Incentive',
            value: '1',
          },
          {
            label: 'Non Incentive',
            value: '0',
          },
        ],
        productTypeOption: [
          {
            label: 'APP',
            value: 'APP',
          },
          {
            label: 'OPTION',
            value: 'OPTION',
          },
        ],
        payoutOption: [
          {
            label: 'CPI',
            value: 'CPI',
          },
          {
            label: 'CPA',
            value: 'CPA',
          },
          {
            label: 'CPL',
            value: 'CPL',
          },
        ],
        platformsOption: [
          {
            label: 'IOS',
            value: 'IOS',
          },
          {
            label: 'ANDROID',
            value: 'ANDROID',
          },
        ],
        downloadTypeOption: [
          {
            label: 'GP',
            value: 'GP',
          },
          {
            label: 'ITUNES',
            value: 'ITUNES',
          },
          {
            label: 'Others',
            value: 'Others',
          },
        ],
        gidIdfaNeedsOption: [
          {
            label: 'YES',
            value: 'YES',
          },
          {
            label: 'NO',
            value: 'NO',
          },
        ],
        withEventOption: [
          {
            label: 'NO',
            value: '1',
          },
          {
            label: 'YES',
            value: '0',
          },
        ],
        sourceList: [],
        creativechecked: false,
        typeOption: [
          {
            label: 'Private',
            value: 'private',
          },
          {
            label: 'Public',
            value: 'public',
          },
        ],
        creativeComposeOption: [
          {
            label: '忽略布局',
            value: '0',
          },
          {
            label: '靠左',
            value: '1',
          },
          {
            label: '靠右',
            value: '2',
          },
          {
            label: '顶部',
            value: '3',
          },
          {
            label: '底部',
            value: '4',
          },
          {
            label: '左上角',
            value: '5',
          },
          {
            label: '左下角',
            value: '6',
          },
          {
            label: '右上角',
            value: '7',
          },
          {
            label: '右下角',
            value: '8',
          },
          {
            label: '正中',
            value: '9',
          },
        ],
        showDynamicOption: [
          {
            label: 'please choose',
            value: '',
          },
          {
            label: '否',
            value: '0',
          },
          {
            label: '是',
            value: '1',
          },
        ],
        showAdTypeOption: [
          {
            label: 'please choose',
            value: '',
          },
          {
            label: 'banner',
            value: '1',
          },
          {
            label: 'interest',
            value: '2',
          },
          {
            label: 'native',
            value: '3',
          },
          {
            label: 'native',
            value: '4',
          },
        ],
        showCloseOption: [
          {
            label: 'please choose',
            value: '',
          },
          {
            label: '无关闭按钮',
            value: '0',
          },
          {
            label: '左上角关闭',
            value: '1',
          },
          {
            label: '右上角关闭',
            value: '2',
          },
          {
            label: '左下角关闭',
            value: '3',
          },
          {
            label: '右下角关闭',
            value: '4',
          },
        ],
        rules: {
          prod: [{ required: true, message: 'please add Pkg Name', trigger: 'blur' }],
          offerName: [{ required: true, message: 'please add offerName', trigger: 'blur' }],
          previewLink: [{ required: true, message: 'please add previewLink', trigger: 'blur' }],
          revenue: [{ required: true, message: 'please add revenue', trigger: 'blur' }],
          countries: [{ required: true, message: 'please add countries', trigger: 'blur' }],
          platforms: [
            {
              required: true,
              message: 'please choice platform',
              trigger: 'change',
            },
          ],
          currency: [
            {
              required: true,
              message: 'Please add currency',
              trigger: ['blur', 'change'],
            },
          ],
          sourceId: [
            {
              required: true,
              message: 'please choice sourceId',
              trigger: 'change',
            },
          ],
          sourceOfferId: [
            {
              required: true,
              message: 'please add sourceOfferId',
              trigger: 'blur',
            },
          ],
          sourceTrackingLink: [
            {
              required: true,
              message: 'please add sourceTrackingLink',
              trigger: 'blur',
            },
          ],
          type: [
            {
              required: true,
              message: 'please select Control type',
              trigger: 'blur',
            },
          ],
          clickCapDaily: [
            {
              required: true,
              message: 'please add clickCapDaily',
              trigger: 'blur',
            },
          ],
        },
      };
    },
    mounted() {
      this.getSourcesList();
      let offer = sessionStorage.getItem('copyOffer');
      if (offer != undefined && offer != null) {
        let copy_offer = JSON.parse(sessionStorage.getItem('copyOffer'));
        if (Object.keys(copy_offer).length > 0) {
          this.copyOffer(copy_offer.offerId);
          sessionStorage.removeItem('copyOffer');
        }
      }
    },
    methods: {
      getSourcesList() {
        this.loading = true;
        getSourceList().then((response) => {
          this.loading = false;
          if (response.code === 200) {
            this.sourceList = response.result.map((item) => ({
              value: item.id,
              label: `${item.id} - ${item.company}`,
            }));
          }
        });
      },
      handleBeforeUploadIcon(file) {
        const fileName = file.name;
        fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
        this.uploadIconData = {
          sourceId: this.addData.sourceId,
          pkgName: this.addData.pkgName,
          prodCategory: this.addData.prodCategory,
        };
        this.uploadIconloading = true; // 开启上传中状态
      },
      handleUploadSuccessIcon(res) {
        this.uploadIconloading = false; // 关闭上传中状态
        if (res.status.code == 0) {
          this.$message.success('Icon上传成功');
          var imgurl = res.data.resUrl;
          var imgSize = res.data.resSize;
          if (this.addData.iconSize == null) {
            this.addData.iconSize = imgSize;
          } else {
            this.addData.iconSize = this.addData.iconSize + ',' + imgSize;
          }

          if (this.addData.iconUrl == null) {
            this.addData.iconUrl = imgurl;
          } else {
            this.addData.iconUrl = this.addData.iconUrl + ',' + imgurl;
          }
          var id = imgurl.substring(imgurl.length - 10, imgurl.length - 4);
          document.querySelector('#upload_icon_list').innerHTML = this.iconHtml(id, imgurl);
        } else if (res.status.code == 2) {
          this.$message.error('sourceId or pkgName could not be empty!');
        } else {
          this.$message.error('error : ' + res.status.msg);
        }
      },
      handleUploadErrorIcon() {
        this.uploadIconloading = false; // 关闭上传中状态
        this.$message.error('上传失败');
      },
      iconHtml(id, imgurl) {
        var iconHtmlmain = '';
        iconHtmlmain +=
          '<dd class="item_img" id="' +
          id +
          '"><div class="operate"><i onclick=UPLOAD_IMG_DEL1("' +
          id +
          '","' +
          imgurl +
          '") class="el-icon-close"></i></div><img src="' +
          imgurl +
          '" class="img" ><input type="hidden" name="dzd_img[]" value="' +
          imgurl +
          '" /></dd>';
        return iconHtmlmain;
      },
      handleBeforeUpload(file) {
        const fileName = file.name;
        fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
        if (this.creativechecked) {
          this.uploadData = {
            sourceId: this.addData.sourceId,
            pkgName: this.addData.pkgName,
            prodCategory: this.addData.prodCategory,
            isNeedZoom: 1,
          };
        } else {
          this.uploadData = {
            sourceId: this.addData.sourceId,
            pkgName: this.addData.pkgName,
            prodCategory: this.addData.prodCategory,
          };
        }
        this.uploadloading = true; // 开启上传中状态
      },
      handleUploadSuccess(res) {
        this.uploadloading = false; // 关闭上传中状态
        if (res.status.code == 0) {
          this.$message.success('文件上传成功');
          var imgurl = res.data.resUrl;
          var imgSize = res.data.resSize;

          if (this.addData.creativeSize == null) {
            this.addData.creativeSize = imgSize;
          } else {
            this.addData.creativeSize = this.addData.creativeSize + ',' + imgSize;
          }
          var imgurls = imgurl.split(',');
          for (var i = 0; i < imgurls.length; i++) {
            var id = imgurl.substring(imgurls[i].length - 10, imgurls[i].length - 4);
            this.createHtml(id, imgurl);
            if (this.addData.creativeUrl == null) {
              this.addData.creativeUrl = imgurl[i];
            } else {
              this.addData.creativeUrl = this.addData.creativeUrl + ',' + imgurl[i];
            }
          }
        } else if (res.status.code == 2) {
          this.$message.error('sourceId or pkgName could not be empty!');
        } else {
          this.$message.error('error : ' + res.status.msg);
        }
      },
      createHtml(id, imgurl) {
        var htmlmain = '';
        htmlmain +=
          '<dd class="item_img" id="' +
          id +
          '"><div class="operate"><i onclick=UPLOAD_IMG_DEL1("' +
          id +
          '","' +
          imgurl +
          '") class="el-icon-close"></i></div><img src="' +
          imgurl +
          '" class="img" ><input type="hidden" name="dzd_img[]" value="' +
          imgurl +
          '" /></dd>';
        return htmlmain;
      },
      handleUploadError() {
        this.uploadloading = false; // 关闭上传中状态
        this.$message.error('上传失败');
      },
      platformsChange(val) {
        if (val == 'IOS') {
          this.addData.downloadType = 'ITUNES';
        } else if (val == 'ANDROID') {
          this.addData.downloadType = 'GP';
        }
      },
      importOfferclick(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            insertOffer({ ...this.addData }).then((response) => {
              if (response.code == 200) {
                this.$message({
                  message: 'import success!',
                  type: 'success',
                });
              } else {
                this.$message.error(response.message);
              }
            });
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      SelectPayout(val) {
        this.isCPI = val === 'CPI';
        this.$set(this.rules.prod[0], 'required', this.isCPI);
      },
      copyOffer(offerId) {
        //获取offer详情数据
        getOfferDetail(offerId).then((response) => {
          if (response.code === 200) {
            const result = response.result;
            if (result.controlType === 'api') {
              result.controlType = '';
            }
            this.addData = response.result;
          } else {
            this.$message.error(response.message);
          }
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .infotitle {
    border-bottom: 1px solid #f0f0f0;
    margin: 10px 0;
    margin-left: 10px;

    label {
      display: block;
      margin: 30px 0;
    }
  }

  #upload_icon_list {
    margin: 10px 0 0 20px;
  }

  #upload_creative_list {
    margin: 10px 0 0 20px;
  }
</style>
