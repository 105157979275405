import request from 'utils/request';
const api = {
  INSERT_OFFER: '/api/storage/offer',
};
export function insertOffer(data) {
  return request({
    url: api.INSERT_OFFER,
    method: 'post',
    data,
  });
}
